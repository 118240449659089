<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-4/5 sm:m-0 m-4">
            <div class="vx-card">
                <div class="vx-row">
                    <div class="vx-col hidden sm:w-full md:w-full lg:block lg:w-2/3 bg-image text-center p-8">
                        <img src="../../../assets/images/logo/reset-password.png" width="90%" height="100%" alt="">
                    </div>
                    <div class="vx-col sm:w-full md:w-full lg:w-1/3 m-auto pl-8">
                        <div class="w-full">
                            <!-- <div class="w-full pr-4 flex mt-4">
                                <span class="font-bold">Bạn quên mật khẩu?</span>
                            </div>
                            <div class="w-full pr-4 flex text-">
                                <span class="text-grey" style="font-size:12px">Vui lòng nhập email để nhận hướng dẫn cài đặt lại mật khẩu.</span>
                            </div> -->

                            <div class="w-full pr-4 mt-2">
                                <vs-button v-if="activeIconEyeNewPassWord" type="flat" class="float-right mt-6 round" icon="icon-eye"
                                   icon-pack="feather" icon-no-border button-no-border @click="HiddenShowNewPassWord"
                                   style="margin-left: -68px"></vs-button>
                                <vs-button v-if="activeIconEyeOffNewPassWord" type="flat" class="float-right mt-6 round" icon="icon-eye-off"
                                        icon-pack="feather" icon-no-border @click="HiddenShowNewPassWord"
                                        style="margin-left: -68px"></vs-button>
                                <vs-input name="newPassWord" v-validate="{required: true, min: 6}" :type="typeOptionNewPassWord" icon-pack="feather" icon="icon-lock" id="text-account" class="w-full" label="Mật khẩu mới" v-model="password" placeholder="Vui lòng nhập mật khẩu mới...."></vs-input>
                                <span class="text-danger text-sm">{{ errors.first("newPassWord") }}</span>
                            </div>
                            <div class="w-full pr-4 mt-2">
                                <vs-button v-if="activeIconEyeReNewPassWord" type="flat" class="float-right mt-6 round" icon="icon-eye"
                                   icon-pack="feather" icon-no-border button-no-border @click="HiddenShowReNewPassWord"
                                   style="margin-left: -68px"></vs-button>
                                <vs-button v-if="activeIconEyeOffReNewPassWord" type="flat" class="float-right mt-6 round" icon="icon-eye-off"
                                        icon-pack="feather" icon-no-border @click="HiddenShowReNewPassWord"
                                        style="margin-left: -68px"></vs-button>
                                <vs-input name="reNewPassWord" v-validate="{required: true, is: password, min: 6}" :type="typeOptionReNewPassWord" icon-pack="feather" icon="icon-lock" id="text-account" class="w-full" label="Xác nhận mật khẩu mới" v-model="confirmPassword" placeholder="Xác nhận mật khẩu mới...."></vs-input>
                                <span class="text-danger text-sm">{{ errors.first("reNewPassWord") }}</span>
                            </div>
                            <div class="w-full mt-2 pr-4">
                                <vs-button class="w-full round" :disabled="!validateForm" @click="resetPassword">Gửi</vs-button>
                                <vs-button icon-pack="feather" icon="icon-chevron-left" class="w-full mt-2 round" type="flat" @click="$router.push('/pages/login')">Quay lại trang đăng nhập</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    data(){
        return {
            password: null,
            confirmPassword:null,
            isSuccess: false,
            typeOption: 'password',
            activeIconEye: true,
            activeIconEyeOff: false,
            typeOptionNewPassWord: 'password',
            activeIconEyeNewPassWord: true,
            activeIconEyeOffNewPassWord: false,
            typeOptionReNewPassWord: 'password',
            activeIconEyeReNewPassWord: true,
            activeIconEyeOffReNewPassWord: false,
            buttonValidateForm: false,
        }
    },
    computed: {
        validateForm() {
        return !this.errors.any()
      }
    },
    methods: {
        HiddenShowReNewPassWord() {
            this.activeIconEyeOffReNewPassWord = !this.activeIconEyeOffReNewPassWord
            if (this.typeOptionReNewPassWord == 'password') {
                this.typeOptionReNewPassWord = 'text'
            } else {
                this.typeOptionReNewPassWord = 'password'
            }
        },
        HiddenShowNewPassWord() {
            this.activeIconEyeOffNewPassWord = !this.activeIconEyeOffNewPassWord
            if (this.typeOptionNewPassWord == 'password') {
                this.typeOptionNewPassWord = 'text'
            } else {
                this.typeOptionNewPassWord = 'password'
            }
        },
        async resetPassword(){
            const result = await this.$validator.validateAll();
            if (!result) return;
            
            let data = this.$route.query;
            const payload = {
                email: data.email,
                otp: data.otp,
                password: this.password
            }
            if (!data) return;
            this.$vs.loading();
            this.$oauth.post('/registration/reset-password', payload).then(() => {
                this.$vs.loading.close();
                this.$router.push('/pages/login');
                this.$vs.notify({
                    color: 'success',
                    text: `Cập nhật mật khẩu thành công`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        }
    }
}
</script>

<style lang="scss">
.bg-image {
    height: 700px; /* You must set a specified height */
    background-color: #1c9863;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    border-radius: 10px 0px 0px 10px;
    
}
</style>